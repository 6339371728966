import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    public initialised: boolean = false;
    public peakActive: boolean = false;
    public loggingIn: boolean = false;
    public loggingInFailed: boolean = false;
    public username: string = '';
    public password: string = '';


    constructor() { }

    ngOnInit(): void {
        setTimeout(() => {
            this.initialised = true;
        }, 1000);
    }

    onPeak(value: boolean) {
        if (this.initialised) {
            this.peakActive = value;
        }
    }

    login() {
        //this.loggingIn = true;

        //setTimeout(() => {
        //    this.loggingIn = false;
        //    this.loggingInFailed = true;
        //    setTimeout(() => {
        //        this.loggingInFailed = false;
        //    }, 3000);
        //}, 3000)
    }
}
