<div class="absolute top-0 w-full flex flex-row">
    <div class="flex-1"></div>
    <div class="m-1">
        <app-button [loading]="loggingIn" [text]="loggingInFailed ? 'Failed' : 'Login'" [class.failed]="loggingInFailed" [rzClass]="(loggingInFailed ? 'bg-red-400' : 'bg-company') + ' border border-transparent shadow-sm text-sm font-medium rounded-md text-white h-38px'" (click)="login()">
        </app-button>
    </div>
</div>

<div class="flex h-full sm:w-9/12 w-11/12 self-center max-w-screen-md">
    <div class="self-center w-full h-80px sm:h-210px relative">
        <div class="flex peak transition transform ease-in-out absolute duration-700 h-80px sm:h-210px z-20">
            <img class="w-full self-center" src="/assets/manawaroa-logo.png">
        </div>
    </div>
</div>

<img class="img absolute w-full h-full" src="/assets/background.jpg">